import React,{useState,useEffect, useRef} from 'react';
import StateUpdators from '../lib/StateUpdators';
import Utils from '../lib/Utils';
import Error from './tools/Error';
import Loader from './tools/Loader';
import ActionModal2 from './tools/ActionModal2';
import ReportError from './tools/ReportError';
import RightSidePopin from './tools/RightSidePopin';
import { useParams } from 'react-router-dom';
import Texts from '../lib/Texts';
import AccountHeader from './tools/AccountHeader';
import Assets from '../lib/Assets';
import { Button, FormControlLabel, Switch } from '@mui/material';
import Separator from './tools/Separator';
import BaseName from '../lib/BaseName';
import QuickHelpView from './tools/QuickHelpView';


/**
 * 
 * @returns 
 */
const SubscriptionSuccessful = () => {
    console.log('{SubscriptionSuccessful}');
    let token = useRef(useParams().token);
    const _info = {
        planInfo: {
            title: 'Test title',
            currencyLetter: 'R',
            price: 30.00,
            expires: {
                day: 'Tue',
                month: 9,
                date: 26,
                year: 2024,
                time: '12 Oclock'
            }
        }
    }
    const [info, setOrderInfo] = useState({
        fetched: true,
        info: {..._info}
    });
    const [autoRenew, setAutoRenew] = useState({
        state: true,
        label: 'ON'
    });
    if(token.current) {
        token = JSON.parse(Utils.base64Decode(token.current));
    }
    StateUpdators.setSuccessOrderInfo = setOrderInfo;
    const _autoRenew = useRef(autoRenew.state);
    console.log('_autoRenew=',_autoRenew);
    useEffect(() => {
        if(!token.orderId) {
            Utils.handleError({error: Texts.errorOrderId});
        } else {
            Utils.verifyPayment({orderId: token.orderId});
        }
        Utils.addAnalytics({
            page: 'Subscriptions',
            url: window.location.href,
            title: 'NS.Subscriptions.Success'
        });
        Utils.setSubscriptionAutoRenew({showFeedback: false, config: {
            name: 'SubscriptionAutoRenew',
            enable: _autoRenew.current,
            orderId: token.orderId
        }});
    },[]);
    return (
        <>
        <AccountHeader showPopupIfAccountIncomplete={true} />
        <div className='w-80 h-centred bkg-w rounded nchfh-kfhf shadow box-b animate__animated animate__fadeIn'>
            {
                info.fetched ? 
                <>
                <div className='mfjd-oeo animate__animated animate__fadeIn'>
                    <img alt='' className='jsd-ppw' src={Assets.icons.statusOk} />
                    <h4>Your payment was successful.</h4>
                    <div className='ss-wpsjs'>
                        <div className='msjs-wsnsm shadow'>
                            <div className='njs-psos box-b'>
                                <h3>Subscription Plan:</h3>
                            </div>
                            <div className='mjd-esks box-b'>
                                <h3>{`${info.info.planInfo.title}`}</h3>
                            </div>
                        </div>
                        <div className='msjs-wsnsm shadow'>
                            <div className='njs-psos box-b'>
                                <h3>Price/Amount:</h3>
                            </div>
                            <div className='mjd-esks box-b'>
                                <h3>{info.info.planInfo.currencyLetter}{info.info.planInfo.price}</h3>
                            </div>
                        </div>
                        <div className='msjs-wsnsm shadow'>
                            <div className='njs-psos box-b'>
                                <h3>Validity:</h3>
                            </div>
                            <div className='mjd-esks box-b'>
                                <h3>Expires on {info.info.planInfo.expires.day}, {info.info.planInfo.expires.month} {parseInt(info.info.planInfo.expires.date)}, {info.info.planInfo.expires.year} at {info.info.planInfo.expires.time}</h3>
                            </div>
                        </div>
                        <div className='msjs-wsnsm shadow'>
                            <div className='njs-psos box-b'>
                                <h3>Auto Renew <b className='shjsjs-pow ptr' onClick={() => {
                                    Utils.showMoreInfo({content: 'aboutAutoRenew'})
                                }} title='Enable auto renew?'>?</b>:</h3>
                            </div>
                            <div className='mjd-esks box-b'>
                                <FormControlLabel control={<Switch onChange={(e) => {
                                    setAutoRenew({
                                        state: e.target.checked,
                                        label: e.target.checked ? 'ON':'OFF'
                                    });
                                    _autoRenew.current = e.target.checked;
                                    Utils.setSubscriptionAutoRenew({showFeedback: true, config: {
                                        name: 'SubscriptionAutoRenew',
                                        enable: _autoRenew.current,
                                        orderId: token.orderId
                                    }});
                                }} checked={autoRenew.state} size='large' color='primary' />} label={autoRenew.label} />
                            </div>
                        </div>
                    </div>
                    <Separator style={{height: '30px'}} />
                    <Button title='Begin your access now' className='ptr' onClick={() => {
                        window.location = `/${BaseName.name}/curriculum`;
                    }}>
                        Begin Your Access Now
                    </Button>
                </div>
                </> : <></>
            }
        </div>
        <Error />
        <RightSidePopin />
        <ReportError />
        <Loader />
        <ActionModal2 />
        <QuickHelpView />
        </>
    )
}

export default SubscriptionSuccessful;