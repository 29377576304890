import React, { useEffect, useState } from 'react'
import Utils from '../../lib/Utils'
import StateUpdators from '../../lib/StateUpdators'
import '../../css/quick_help_view.css'
import CloseIcon from './CloseIcon'
import LoadingComponent from './LoadingComponent'
import { Dialog } from '@mui/material'

/**
 * The Quick Help View 
 * @param {object} info The props object
 * @returns 
 */
const QuickHelpView = () => {
    console.log('{QuickHelpView}')
    const [info, setQuickHelpView] = useState({
        show: false,
        props: {},
        fetched: false,
        Content: () => 
        <div className='jshs-lls w-90 centred content-centre'>
            <LoadingComponent />
        </div>
    })
    //console.log('info=',info)
    StateUpdators.setQuickHelpView = setQuickHelpView
    useEffect(() => {
        if(info.show && !info.fetched) {
            Utils.fetchQuickHelpContent({...info.props})
        }
    },[info])
    return (
        <>
        {
            info.show ?
            <Dialog open={info.show}>
                <div className='jdhs-qjdjd wht-bkg rounded shadow animate__animated animate__fadeIn'>
                    <div className='wnsns-pepe'>
                        <div className='mkfjd-epep text-centre content-centre'>
                            <h3 className='mgn-0'>Quick Help</h3>
                        </div>
                        <div>
                            <CloseIcon onClose={() => {
                                setQuickHelpView({show: false, props: {}, fetched:true, Content: () => <></>})
                            }} />
                        </div>
                    </div>
                    <div className='dkd-ldlsk box-b w-90 centred'>
                        <info.Content />
                    </div>
                </div>
            </Dialog>
            : <></>
        }
        </>
    )
}

export default QuickHelpView