
/**
 * Empty function
 */
const ef = () => {}
/**
 * State updators
 */
const StateUpdators = {
    setQuickHelpView: ef,
    setLangScope: ef,
    _setLanguages: ef,
    setLanguages: ef,
    setCourseTitle: ef,
    setResourcesTitle: ef,
    setValidationPIN: ef,
    setValidatingAction: ef,
    setOldLinkShow: ef,
    setLoggingIn: ef,
    setWebContent: ef,
    setErrorOnHomeSchoolerForm: ef,
    setCancelled: ef,
    setSuccessOrderInfo: ef,
    setFormInfo: ef,
    setLibrary: ef,
    setPlanInfoContent: ef,
    setPaymentInitHandler: ef,
    setCurriculumPlans: ef,
    setCheckBoxError: ef,
    setHeader1: ef,
    setContent: ef,
    setHeader: ef,
    setLoginFormViewLoginPart: ef,
    setLoginFormDialogState: ef,
    setFadeMode: ef,
    setImage: ef,
    setQuickHelpPopup: ef,
    setCambridgeProgram: ef,
    setQuickInfoCollectFormContent: ef,
    setIsEQLesson: ef,
    setPINSContent2: ef,
    setPINSContent: ef,
    setCcurriculumsCurriculumsForPINSView: ef,
    setLessonsOnSearch: ef,
    setSearching: ef,
    setSearchResultsFound: ef,
    setSpecialAccountSettingsContent: ef,
    setCurrentAccessCountry: ef,
    setStudentSettingsContent: ef,
    setExamInfo: ef,
    setEducatorDashboardActionsContent: ef,
    setEducatorDashboardAction: ef,
    setStudentsData: ef,
    setManageStudentGroupInput: ef,
    setRowSelectionModel: ef,
    setManageStudentGroupsInfo: ef,
    setManageStudentsPageContent: ef,
    setSettingsPageContent: ef,
    setPaceAlignment: ef,
    setLessonsCompleted: ef,
    setLanguage: ef,
    setUserProfileInfo: ef,
    setTechTalkContent: ef,
    setUploading: ef,
    setDBAlbumContent: ef,
    setLessonTaskPageModal: ef,
    setMinimizedView: ef,
    setFullscreenModal: ef,
    setActivePINStatus2: ()=> {},
    setActivePINStatus: ef,
    setPIN: ef,
    setValidating: ef,
    setValidationResults: ef,
    setDigitalBadgeC: ef,
    setPluggableLoaderConfigs: ef,
    setProgressBadgeCongtent: ef,
    setStudentProgressBadge: ef,
    setStudentProgress: ef,
    setGuidesContent: ef,
    setSaveButtonState: ef,
    setButtonLoading: ef,
    setUser: ef,
    setUserAvater: ef,
    setInputFile: ef,
    setActionModal2: ef,
    setCanShowQuiz: ef,
    setPageTitle: ef,
    setStudentProgressData: ef,
    setSessionCleared: ef,
    setStartTimestamp: ef,
    setViewPastResultsButton: ef,
    setStartTime: ef,
    setScrollFunctionsOnMobileDevices: ef,
    setStepsImage: ef,
    setActionModal: ef,
    setAsnweredQuestions: ef,
    setActiveQuizInfo: ef,
    setCandidateDetails: ef,
    setQuizPageContent: ef,
    setDisabled: ef,
    setShowQuickInfoCollectForm: ef,
    setAboutContent: ef,
    setSubmitButtonDisabled: ef,
    setErrorInputForm: ef,
    setErrorReportSent: ef,
    setRightSidePopup: ef,
    setSidefeedback: ef,
    setLessonHeaderInfo: ef,
    setCourseHeaderInfo: ef,
    setClassActionContent: ef,
    setUserEarnedDigitalbadges: ef,
    setUserDetails: ef,
    setLesson: ef,
    setMoreInfoViewInfo: ef,
    setCurriculum: ef,
    setLoaderConfigs: ef,
    setCourse: ef,
    setError: ef,
}

export default StateUpdators;