import React, { useEffect } from 'react';
import BaseName from '../lib/BaseName';
import Utils from '../lib/Utils';
import Loader from './tools/Loader';
import QuickHelpView from './tools/QuickHelpView';

const Logout = () => {
    console.log('{Logout}');
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Logout',
            url: window.location.href,
            title: `NS.Logout`
        });
        ((async () => {
            await Utils.logoutUser();
            window.location = `/${BaseName.name}/`;
        }))();
    });
    return (
        <>
        <Loader />
        <QuickHelpView />
        </>
    )
}

export default Logout;