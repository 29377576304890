import React, { useEffect, useRef, useState } from 'react';
import Separator from './Separator';
import Texts from '../../lib/Texts';
import Assets from '../../lib/Assets';
import { Edit } from '@mui/icons-material';
import CharacterTraits from '../../lib/CharacterTraits';
import Utils from '../../lib/Utils';
import { UpdateCharTraits, UpdateLanguageSettings } from './Forms';
import SpecialAccountSettings from './SpecialAccountSettings';
import CurrentLanguagesList from './CurrentLanguagesList';
import StateUpdators from '../../lib/StateUpdators';
import LanguageScopes from '../../lib/LanguageScopes';


/**
 * The settings app for all except students
 * @param {object} info The info object 
 * @returns 
 */
const SettingsApp = ({info}) => {
    console.log('{SettingsApp}')
    const [langScope, setLangScope] = useState(LanguageScopes[info.preferences.langScope])
    const langs = useRef(info.preferences.language)
    StateUpdators.setLangScope = setLangScope
    useEffect(() => {
        StateUpdators._setLanguages(langs.current.map(lang => lang.title).join(', '))
    },[])
    return (
        <div className='hddg-info-hdhdg w-80 centered box-b rounded shadow mnhd0jdh animate__animated animate__fadeIn'>
            <h3>Manage settings</h3>
            <p className='shady-blk'>{Texts.aboutSettings}</p>
            <div className='hdgsg-jfhf-s w-90 centered box-b'>
                <div className='box-b ndhgd'>
                    <h3>Your profile settings at Computers 4 Kids</h3>
                    <p className='hfgg-hfhg shady-blk'>{Texts.aboutSettingsTwo}</p>
                </div>
                <div>
                    <img alt='About Profile' className='fhf-jghg' src={Assets.misc.settings} />
                </div>
            </div>
            <div className='basic-infolhdgd box-b bdr-bl rounded w-90 centred'>
                <div className='hdgdg-hhdr'>
                    <div>
                        <h3 title={Texts.theseForYourClass} className='hs-hfbd ptr'>Class Settings</h3>
                    </div>
                    <div className='edit-ndhd-btns box-b'>
                    </div>
                </div>
                {
                    info.isPACE &&
                    <>
                    <div className='hdgdg-hhdr'>
                        <div>
                            <h4 title={Texts.theseForYourClass} className='hs-hfbd jhs-kdh ptr'>Character Traits</h4>
                        </div>
                        <div className='edit-ndhd-btns box-b'>
                            <Edit className='ptr' titleAccess='Update Character Traits settings' onClick={() => {
                                Utils.showModal2({
                                    Content: () => <UpdateCharTraits current={{...info.traitConfigs, studentGroups: info.studentGroups}} />
                                })
                            }} />
                        </div>
                    </div>
                    <div className='info-jhdgd'>
                        <div className='flejd-name'>
                            <p className='hfgg-hfhg shady-blk'>Character Traits View Option:</p>
                        </div>
                        <div className='valie-hfgfg'>
                            <p className='hfgg-hfhg shady-blk mdj-kf'>{CharacterTraits.viewOptions[info.traitConfigs.viewOption]}</p>
                        </div>
                    </div>
                    <div className='info-jhdgd'>
                        <div className='flejd-name'>
                            <p className='hfgg-hfhg shady-blk'>Applies To Which Class/Group:</p>
                        </div>
                        <div className='valie-hfgfg'>
                            <p className='hfgg-hfhg shady-blk mdj-kf'>{info.traitConfigs.groupTitle}</p>
                        </div>
                    </div>
                    </>
                }
                <Separator styles={{
                    height: '50px'
                }} />
                <div className='hdgdg-hhdr'>
                    <div>
                        <h4 title={Texts.theseForYourClass} className='hs-hfbd jhs-kdh ptr'>Language</h4>
                    </div>
                    <div className='edit-ndhd-btns box-b'>
                        <Edit className='ptr' titleAccess='Update language settings' onClick={() => {
                            Utils.showModal2({
                                Content: () => <UpdateLanguageSettings supportedLanguages={info.supportedLanguages} current={{...info.preferences}} />
                            })
                        }} />
                    </div>
                </div>
                <div className='info-jhdgd'>
                    <div className='flejd-name'>
                        <p className='hfgg-hfhg shady-blk'>Current Languages:</p>
                    </div>
                    <div className='valie-hfgfg'>
                        <CurrentLanguagesList />
                    </div>
                </div>
                <div className='info-jhdgd'>
                        <div className='flejd-name'>
                            <p className='hfgg-hfhg shady-blk'>Applies To Which Users:</p>
                        </div>
                        <div className='valie-hfgfg'>
                            <p className='hfgg-hfhg shady-blk mdj-kf'>{langScope}</p>
                        </div>
                    </div>
            </div>
            <Separator styles={{
                height: '50px'
            }} />
            <SpecialAccountSettings />
        </div>
    )
}

export default SettingsApp;