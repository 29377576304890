import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Utils from '../lib/Utils';
import AbsoluteCOLLogo from './tools/AbsoluteCOLLogo';
import HomePageNavMenu from './tools/HomePageNavMenu';
import MobileNavMenu from './tools/MobileNavMenu';
import SupportApp from './tools/SupportApp';
import QuickHelpView from './tools/QuickHelpView';

/**
 * Support management and ticketing system UI
 */
const Support = () => {
    console.log('{Support}');
    const params = useParams();
    const token = params.token;
    let tokenData = {};
    if(token) {
        tokenData = JSON.parse(Utils.base64Decode(token));
    }
    const _token = useRef(tokenData);
    console.log('_token=',_token);
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Support',
            token: _token,
            url: window.location.href,
            title: 'NS.SupportPageVisit'
        });
    },[]);
    return (
        <>
        <div className='hdgs-jdhd animate__animated animate__fadeIn'>
            <div className='mshsh-lkdks'>
                <div className='ksjsjs'>
                    <AbsoluteCOLLogo styles={{
                        left: '20px',
                        top: '20px'
                    }} />
                </div>
                <div className='nshsghd-ldkdj-nav'>
                    <HomePageNavMenu page={'signup'} />
                </div>
            </div>
            <MobileNavMenu page={'signup'} />
            <SupportApp token={token} />
        </div>
        <QuickHelpView />
        </>
    )
}

export default Support;