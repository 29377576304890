import React,{useState,useEffect,useRef} from 'react';
import StateUpdators from '../lib/StateUpdators';
import Error from './tools/Error';
import Loader from './tools/Loader';
import ActionModal2 from './tools/ActionModal2';
import ReportError from './tools/ReportError';
import RightSidePopin from './tools/RightSidePopin';
import SmallLoader from './tools/SmallLoader';
import AccountHeader from './tools/AccountHeader';
import Utils from '../lib/Utils';
import { useParams } from 'react-router-dom';
import Texts from '../lib/Texts';
import QuickHelpView from './tools/QuickHelpView';


/**
 * 
 * @returns 
 */
const Payments = () => {
    console.log('{Payments}');
    const token = useRef(useParams().token);
    const [paymentHandler, setPaymentHandler] = useState({
        Content: () => (
            <>
            <AccountHeader showPopupIfAccountIncomplete={true} />
            <div className='cbbdg-curriculums-fhgf animate__animated animate__fadeIn'>
                <div className='w-80 h-centred bkg-w rounded nchfh-kfhf shadow box-b'>
                    <SmallLoader customClasses='small-loader' />
                </div>
            </div>
            </>)
    });
    StateUpdators.setPaymentInitHandler = setPaymentHandler;
    useEffect(() => {
        if(!token.current) {
            Utils.handleError({error: Texts.errorPaymentInit});
        } else {
            Utils.fetchSubscriptionPlan({token: token.current});
        }
    },[]);
    return (
        <>
        {
            <paymentHandler.Content />
        }
        <Error />
        <RightSidePopin />
        <ReportError />
        <Loader />
        <ActionModal2 />
        <QuickHelpView />
        </>
    )
}

export default Payments;