import React,{useState,useEffect, useRef} from 'react'
import { useParams } from 'react-router-dom'
import StateUpdators from '../lib/StateUpdators'
import Utils from '../lib/Utils'
import Error from './tools/Error'
import Loader from './tools/Loader'
import ActionModal2 from './tools/ActionModal2'
import ReportError from './tools/ReportError'
import RightSidePopin from './tools/RightSidePopin'
import QuickHelpView from './tools/QuickHelpView'


/**
 * 
 * @returns 
 */
const Curriculum = () => {
    console.log('{Curriculum}')
    const params = useParams()
    const [curriculum, setCurriculum] = useState({
        Content: () => (<></>)
    })
    StateUpdators.setCurriculum = setCurriculum
    const ref = useRef(params)
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Curriculum',
            url: window.location.href,
            title: 'NS.CurriculumPageVisit'
        })
        if(ref.current.curriculumId) {
            Utils.fetchCurriculumCourses({
                curriculumId: parseInt(ref.current.curriculumId)
            })
        } else {
            Utils.fetchCurriculums()
            Utils.addAnalytics({
                page: 'Curriculums',
                url: window.location.href,
                title: 'NS.CurriculumsPageVisit'
            })
            Utils.shouldMergeStudentLogins()
        }
    },[])
    return (
        <>
        {
            <curriculum.Content />
        }
        <Error />
        <RightSidePopin />
        <ReportError />
        <Loader />
        <ActionModal2 />
        <QuickHelpView />
        </>
    )
}

export default Curriculum;