import React, { useEffect, useRef, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import BaseName from '../../lib/BaseName';
import Utils from '../../lib/Utils';
import StateUpdators from '../../lib/StateUpdators';

/**
 * The TermsAgreeTickBox view
 * @returns 
 */
const TermsAgreeTickBox = () => {
    console.log('{TermsAgreeTickBox}');
    const [checked, setChecked] = useState(false)
    Utils.registrations.checkedTermsCheckBox = checked
    const [error, setError] = useState(false)
    const _checked = useRef(checked)
    StateUpdators.setCheckBoxError = setError
    useEffect(() => {
        if(_checked.current && error) {
            setError(false)
        }
    },[error])
    return (
        <>
        <div className={`hshs-leoejs box-b ${error?'fjdhdh-error-check-box':''}`}>
            <div>
                <Checkbox
                    checked={checked}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={(e) => {
                        setChecked(e.target.checked);
                        if(e.target.checked && error) {
                            setError(false);
                        }
                    }}
                />
            </div>
            <div>
                <p className='jfjd-ldk mgn-0'>I agree to the <a target='__blank' href={`/${BaseName.name}/content/terms-and-conditions`}>Terms and Conditions</a> and I have read the <a target='__blank' href={`/${BaseName.name}/content/privacy-policy`}>Privacy Policy</a>.</p>
            </div>
        </div>
        </>
    )
}

export default TermsAgreeTickBox;