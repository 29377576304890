import React, { useEffect } from 'react';
import AbsoluteCOLLogo from './tools/AbsoluteCOLLogo';
import HomePageNavMenu from './tools/HomePageNavMenu';
import Assets from '../lib/Assets';
import Utils from '../lib/Utils';
import QuickHelpView from './tools/QuickHelpView';

/**
 * StudentAccessClosed view
 * @returns
 */
const StudentAccessClosed = () => {
  console.log('{StudentAccessClosed}');
  useEffect(() => {
    Utils.setPageTitle('Access Closed For Now');
    Utils.addAnalytics({
      page: 'StudentAccessClosed',
      url: window.location.href,
      title: 'NS.StudentAccessClosed',
    });
    window.VANTA.HALO({
      el: '#the-animation',
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 800.0,
      minWidth: 600.0,
    });
  }, []);
  return (
    <>
      <div className='jdhd-kdjks animate__animated animate__fadeIn'>
        <div className='mshsh-lkdks'>
          <div className='ksjsjs'>
            <AbsoluteCOLLogo
              styles={{
                left: '20px',
                top: '20px',
              }}
            />
          </div>
          <div className='nshsghd-ldkdj-nav'>
            <HomePageNavMenu page={'home'} />
          </div>
        </div>
        <div className='cbbdg-curriculums-fhgf animate__animated animate__fadeIn'>
          <div id='the-animation'>
            <div className='w-60 centred box-b hdhs'>
              <img
                alt=''
                title='Access locked'
                className='kdk-epep ptr'
                src={Assets.icons.locked}
              />
              <h3 className='slks white-text'>
                Access is currently closed. Please wait for your teacher to open
                access for you.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <QuickHelpView />
    </>
  );
};

export default StudentAccessClosed;
