import React, { useEffect, useState } from 'react';
import Theme from '../../lib/Theme';
import Texts from '../../lib/Texts';
import Assets from '../../lib/Assets';
import Separator from './Separator';
import StateUpdators from '../../lib/StateUpdators';
import SmallLoader from './SmallLoader';
import Utils from '../../lib/Utils';
import { Edit } from '@mui/icons-material';
import Forms from './Forms';
import { Button } from '@mui/material';
import CurrentLessonInfo from '../../CurrentLessonInfo';


/**
 * Special account handlers
 */
const SpecialAccountHandlers = {
    /**
     * Change curriculum access country with other settings.
     * This allows a profile to switch their profile's country value so that they can access content for target accessible countries
     * @returns 
     */
    ChangeAccessCountryWithOtherSettings: ({data,withNoAboutContent}) => <ChangeAccessCountryWithOtherSettings data={data} withNoAboutContent={withNoAboutContent} />,
    /**
     * Current country view for user
     * @param {object} data The data object 
     * @returns 
     */
    CurrentCountryView: ({data}) => <CurrentCountryView data={data} />,
    /**
     * Change curriculum access country.
     * This allows a profile to switch their profile's country value so that they can access content for target accessible countries
     * @returns 
     */
    ChangeAccessCountry: ({data,withNoAboutContent}) => <ChangeAccessCountry data={data} withNoAboutContent={withNoAboutContent} />,

    /**
     * When a special account's handler is not defined.
     * @returns 
     */
    DefaultSpecialAccountHandler: () => <DefaultSpecialAccountHandler />
}

const CurrentCountryView = ({data}) => {
    console.log('{SpecialAccountHandlers.CurrentCountryView}')
    const currentCountry = data.countries.filter(country => country.countryId === data.userCountryId)[0]??{};
    return (
        <>
        <div className='jhdg-kdhd'>
            <div>
                <span className='mdhpw-xm'>{currentCountry.flag}</span>
            </div>
            <div className='ns-pwks'>
                <p className='mgn-0'>{currentCountry.country}</p>
            </div>
        </div>
        </>
    )
}

/**
 * 
 * @returns 
 */
const ChangeAccessCountry = ({withNoAboutContent}) => {
    console.log('{SpecialAccountHandlers.ChangeAccessCountry}');
    const [CurrentCountry, setCurrentAccessCountry] = useState({
        data: {},
        C: () => <div className='dgs-pxwmd'><SmallLoader title='Fetching country...' customClasses='sl-w-100 rounded' /></div>
    });
    StateUpdators.setCurrentAccessCountry = setCurrentAccessCountry;
    useEffect(() => {
        Utils.fetchSpecialAccountSettingsInfo({handler: 'ChangeAccessCountry'});
    },[])
    return (
        <>
        {
            (typeof withNoAboutContent !== 'undefined' && withNoAboutContent) ?
            <>
            <div className='basic-infolhdgd box-b bdr-bl rounded w-90 centred'>
                <div className='hdgdg-hhdr'>
                    <div>
                        <h3 title={Texts.theseForYourAccessCountry} className='hs-hfbd ptr'>Access Country Settings</h3>
                    </div>
                    <div className='edit-ndhd-btns box-b'>
                        <Edit className='ptr' titleAccess='Change Access Country' onClick={() => {
                            Utils.showModal2({
                                Content: () => <Forms.ChangeAccessCountry data={CurrentCountry.data} />
                            })
                        }} />
                    </div>
                </div>
                <div className='hdgdg-hhdr'>
                    <div>
                        <h4 title={Texts.theseForYourClass} className='hs-hfbd jhs-kdh ptr'>Current Country</h4>
                    </div>
                    <div className='edit-ndhd-btns box-b'>
                        <CurrentCountry.C />
                    </div>
                </div>
            </div>
            </>
            :
            <>
            <div className={`${Theme.content.mainPage.classes} hsgsg-shdh`}>
                <h3>Manage settings</h3>
                <p className='shady-blk'>{Texts.aboutSettings}</p>
                <div className='hdgsg-jfhf-s w-90 centered box-b'>
                    <div className='box-b ndhgd'>
                        <h3>Your profile settings at Computers 4 Kids</h3>
                        <p className='hfgg-hfhg shady-blk'>{Texts.aboutSettingsTwo}</p>
                    </div>
                    <div>
                        <img alt='About Profile' className='fhf-jghg' src={Assets.misc.settings} />
                    </div>
                </div>
                <div className='basic-infolhdgd box-b bdr-bl rounded w-90 centred'>
                    <div className='hdgdg-hhdr'>
                        <div>
                            <h3 title={Texts.theseForYourAccessCountry} className='hs-hfbd ptr'>Access Country Settings</h3>
                        </div>
                        <div className='edit-ndhd-btns box-b'>
                            <Edit className='ptr' titleAccess='Change Access Country' onClick={() => {
                                Utils.showModal2({
                                    Content: () => <Forms.ChangeAccessCountry data={CurrentCountry.data} />
                                })
                            }} />
                        </div>
                    </div>
                    <div className='hdgdg-hhdr'>
                        <div>
                            <h4 title={Texts.theseForYourClass} className='hs-hfbd jhs-kdh ptr'>Current Country</h4>
                        </div>
                        <div className='edit-ndhd-btns box-b'>
                            <CurrentCountry.C />
                        </div>
                    </div>
                </div>
            </div>
            </>
        }
        <Separator styles={{
            height: '50px'
        }} />
        </>
    )
}

/**
 * 
 * @returns 
 */
const ChangeAccessCountryWithOtherSettings = ({withNoAboutContent}) => {
    console.log('{SpecialAccountHandlers.ChangeAccessCountryWithOtherSettings}');
    const [CurrentCountry, setCurrentAccessCountry] = useState({
        data: {},
        C: () => <div className='dgs-pxwmd'><SmallLoader title='Fetching country...' customClasses='sl-w-100 rounded' /></div>
    });
    StateUpdators.setCurrentAccessCountry = setCurrentAccessCountry;
    const curriculum = Utils.getCachedCurriculumInfo();
    useEffect(() => {
        Utils.fetchSpecialAccountSettingsInfo({handler: 'ChangeAccessCountry'});
    },[])
    return (
        <>
        {
            (typeof withNoAboutContent !== 'undefined' && withNoAboutContent) ?
            <>
            <div className='basic-infolhdgd box-b bdr-bl rounded w-90 centred'>
                <div className='hdgdg-hhdr'>
                    <div>
                        <h3 title={Texts.theseForYourAccessCountry} className='hs-hfbd ptr'>Access Country Settings</h3>
                    </div>
                    <div className='edit-ndhd-btns box-b'>
                        <Edit className='ptr' titleAccess='Change Access Country' onClick={() => {
                            Utils.showModal2({
                                Content: () => <Forms.ChangeAccessCountry data={CurrentCountry.data} />
                            })
                        }} />
                    </div>
                </div>
                <div className='hdgdg-hhdr'>
                    <div>
                        <h4 title={'Current Country'} className='hs-hfbd jhs-kdh ptr'>Current Country</h4>
                    </div>
                    <div className='edit-ndhd-btns box-b'>
                        <CurrentCountry.C />
                    </div>
                </div>
            </div>
            <Separator styles={{
                    height: '10px'
                }} />
                <div className='basic-infolhdgd box-b bdr-bl rounded w-90 centred'>
                    <div className='hdgdg-hhdr'>
                        <div>
                            <h3 title={'Curriculum Settings'} className='hs-hfbd ptr'>Curriculum Settings</h3>
                        </div>
                        <div className='edit-ndhd-btns box-b'>
                        </div>
                    </div>
                    <div className='hdgdg-hhdr'>
                        <div>
                            <h4 title={'Current Lesson Info'} className='hs-hfbd jhs-kdh ptr'>Current Lesson Info</h4>
                        </div>
                        <div className='edit-ndhd-btns box-b'>
                            <Button onClick={() => {
                                Utils.showModal2({
                                    Content: () => <CurrentLessonInfo data={curriculum} />
                                })
                            }}>
                                View Current Lesson Info
                            </Button>
                        </div>
                    </div>
                </div>
            </>
            :
            <>
            <div className={`${Theme.content.mainPage.classes} hsgsg-shdh`}>
                <h3>Manage settings</h3>
                <p className='shady-blk'>{Texts.aboutSettings}</p>
                <div className='hdgsg-jfhf-s w-90 centered box-b'>
                    <div className='box-b ndhgd'>
                        <h3>Your profile settings at Computers 4 Kids</h3>
                        <p className='hfgg-hfhg shady-blk'>{Texts.aboutSettingsTwo}</p>
                    </div>
                    <div>
                        <img alt='About Profile' className='fhf-jghg' src={Assets.misc.settings} />
                    </div>
                </div>
                <div className='basic-infolhdgd box-b bdr-bl rounded w-90 centred'>
                    <div className='hdgdg-hhdr'>
                        <div>
                            <h3 title={Texts.theseForYourAccessCountry} className='hs-hfbd ptr'>Access Country Settings</h3>
                        </div>
                        <div className='edit-ndhd-btns box-b'>
                            <Edit className='ptr' titleAccess='Change Access Country' onClick={() => {
                                Utils.showModal2({
                                    Content: () => <Forms.ChangeAccessCountry data={CurrentCountry.data} />
                                })
                            }} />
                        </div>
                    </div>
                    <div className='hdgdg-hhdr'>
                        <div>
                            <h4 title={Texts.theseForYourClass} className='hs-hfbd jhs-kdh ptr'>Current Country</h4>
                        </div>
                        <div className='edit-ndhd-btns box-b'>
                            <CurrentCountry.C />
                        </div>
                    </div>
                </div>
                <Separator styles={{
                    height: '10px'
                }} />
                <div className='basic-infolhdgd box-b bdr-bl rounded w-90 centred'>
                    <div className='hdgdg-hhdr'>
                        <div>
                            <h3 title={'Curriculum Settings'} className='hs-hfbd ptr'>Curriculum Settings</h3>
                        </div>
                        <div className='edit-ndhd-btns box-b'>
                        </div>
                    </div>
                    <div className='hdgdg-hhdr'>
                        <div>
                            <h4 title={'Current Lesson Info'} className='hs-hfbd jhs-kdh ptr'>Current Lesson Info</h4>
                        </div>
                        <div className='edit-ndhd-btns box-b'>
                            <Button onClick={() => {
                                Utils.showModal2({
                                    Content: () => <CurrentLessonInfo data={curriculum} />
                                })
                            }}>
                                View Current Lesson Info
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        }
        <Separator styles={{
            height: '50px'
        }} />
        </>
    )
}

const DefaultSpecialAccountHandler = () => {
    console.log('{SpecialAccountHandlers.DefaultSpecialAccountHandler}');
    return (
        <>
        <div className={`${Theme.content.mainPage.classes} hsgsg-shdh`}>
            <h3>Settings</h3>
            {Texts.defaultHandler}
        </div>
        </>
    )
}


export default SpecialAccountHandlers;