import React from 'react';
import QuickHelpView from './tools/QuickHelpView';


const ExpiredSubscription = () => {
    console.log('{ExpiredSubscription}');
    return (
        <>
        <QuickHelpView />
        </>
    )
}

export default ExpiredSubscription;