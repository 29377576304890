import React, { useEffect, useState } from 'react';
import AccountHeader from './tools/AccountHeader';
import EducatorDashboardLeftMenu from './tools/EducatorDashboardLeftMenu';
import StateUpdators from '../lib/StateUpdators';
import Error from './tools/Error';
import Utils from '../lib/Utils';
import ActionModal2 from './tools/ActionModal2';
import SideFeedback from './tools/SideFeedback';
import RightSidePopin from './tools/RightSidePopin';
import ReportError from './tools/ReportError';
import QuickHelpView from './tools/QuickHelpView';
import Loader from './tools/Loader';


/**
 * The educator dashboard
 * @returns 
 */
const EducatorDashboard = () => {
    console.log('{EducatorDashboard}');
    const [action, setEducatorDashboardAction] = useState({
        header: '',
        itemId: '',
        Content: () => <></>
    });
    StateUpdators.setEducatorDashboardAction = setEducatorDashboardAction;
    useEffect(() => {
        Utils.setPageTitle('Educator Dashboard')
        Utils.addAnalytics({
            page: 'EducatorDashboard',
            url: window.location.href,
            title: 'NS.EducatorDashboard',
            courseId: 'NA', 
            lessonId: 'NA'
        });
    },[]);
    return (
        <>
        <AccountHeader />
        <EducatorDashboardLeftMenu />
        <div className='animate__animated animate__fadeIn lesson-mfhdh-new'>
            <div className='w-80 h-centred kdjd-yetrw bkg-w rounded nchfh-kfhf shadow box-b'>
                <div className='dash-dhege bdr-btm-bl'>
                    <h3>Educator Dashboard <b className='jdhs0d-jd' dangerouslySetInnerHTML={{__html: action.header}}></b></h3>
                </div>
                <div className='dash-ocudgd'>
                    <action.Content />
                </div>
            </div>
        </div>
        <Error />
        <ReportError />
        <ActionModal2 />
        <SideFeedback />
        <RightSidePopin />
        <QuickHelpView />
        <Loader />
        </>
    )
}

export default EducatorDashboard;