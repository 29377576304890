/**
 * Language settings scopes
 */
const LanguageScopes = {
    user: 'Only Me',
    institution: 'Entire Institution',
    class: 'My Class',
    group: 'Group'
}

export default LanguageScopes