import React, { useEffect } from 'react';
import Utils from '../lib/Utils';
import QuickHelpView from './tools/QuickHelpView';

/**
 * The login View
 * @returns 
 */
const Login = () => {
    console.log('{Login}');
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Login',
            url: window.location.href,
            title: 'NS.Login'
        });
    },[]);
    return (
        <>
        <div className='c4k-login animate__animated animate__fadeIn'>
        </div>
        <QuickHelpView />
        </>
    )
}


export default Login;