import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import StateUpdators from '../lib/StateUpdators';
import Loader from './tools/Loader';
import RenderCourse from './tools/RenderCourse';
import Utils from '../lib/Utils';
import Error from './tools/Error';
import { useDispatch } from 'react-redux';
import { setCourseInfo } from '../lib/StateManagers/CourseInfo';
import ActionModal2 from './tools/ActionModal2';
import ReportError from './tools/ReportError';
import RightSidePopin from './tools/RightSidePopin';
import QuickHelpView from './tools/QuickHelpView';


/**
 * Displays course lessons
 * @returns 
 */
const Course = () => {
    console.log('{Course}');
    const params = useParams();
    const dispatch = useDispatch();
    const token = JSON.parse(Utils.base64Decode(params.token));
    const [course, setCourse] = useState({
        ready: false,
        content: {}
    });
    StateUpdators.setCourse = setCourse;
    dispatch(setCourseInfo(course.content));
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Course',
            courseId: token.courseId,
            url: window.location.href,
            title: 'NS.CoursePageVisit'
        });
        Utils.fetchCourse({
            courseId: token.courseId,
            categoryId: token.categoryId ? token.categoryId : undefined,
            hasSubSubCourses: token.hasSubSubCourses ? token.hasSubSubCourses : undefined
        });
    },[token.courseId,token.categoryId,token.hasSubSubCourses]);
    return (
        <>
        {
            course.ready ? <RenderCourse content={course.content} /> : <Loader />
        }
        <Error />
        <ReportError />
        <ActionModal2 />
        <RightSidePopin />
        <QuickHelpView />
        </>
    )
}

export default Course;