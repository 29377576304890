/**
 * App assets
 */

const Assets = {
    emptyBox: require('../assets/empty_box_1.png'),
    licenseeAgreement: require('../assets/licensee_agreement.png'),
    curriculumBundle: require('../assets/curriculums_bundle.png'),
    poweredByC4K: require('../assets/logos/powered_by_c4k_logo.png'),
    c4kLogo: require('../assets/logos/c4k_logo_512x512.png'),
    colLogo: require('../assets/classesonline512x512.png'),
    colLogoWhite: require('../assets/classesonline512x512_white.png'),
    colLogo2: require('../assets/white_classesonline_logo.png'),
    eLearnerLogo: require('../assets/logos/e_learner.png'),
    rapidTypingLogo:  require('../assets/logos/rapidtyping_logo.png'),
    ICDLLogo: require('../assets/logos/ICDL_Endorsed_Logo_reversed_Transparent.png'),
    licenseeIllustrate: require('../assets/business-opportunity-gulf-region.jpg'),
    smallLoader: require('../assets/icons/small_loader.gif'),
    userOptions: {
        licensee: require('../assets/user_options/licensee.png'),
        homeschooler: require('../assets/user_options/home_schooler.png'),
        school: require('../assets/user_options/school.png'),
        organisation: require('../assets/user_options/organisation.png'),
    },
    eqInfographic: {
        white: require('../assets/eq_infographic_white.png')
    },
    sections: {
        children: [
            require('../assets/heros/image_slide1.png'),
            require('../assets/heros/image_slide2.png'),
            require('../assets/heros/image_slide3.png'),
            require('../assets/heros/image_slide4.png'),
            require('../assets/heros/image_slide5.png'),
            require('../assets/heros/image_slide6.png'),
            require('../assets/heros/image_slide7.png'),
        ],
        illustrate: {
            account: require('../assets/illustrate_account.png'),
            accountCreate: require('../assets/illustrate_account_create.png'),
            minecraft: require('../assets/illustrate_minecraft.png'),
            businessOp: require('../assets/illustrate_busi_opp.png'),
        }
    },
    misc: {
        stillWorkingOnThis: require('../assets/animated-presentation-software-header.gif'),
        aboutPI1: require('../assets/about_profile_c4k.png'),
        settings: require('../assets/setting_and_prefs_c4k.png'),
        miniBotot: require('../assets/robot_icon_mini.png'),
        illustration: require('../assets/illustration.png')
    },
    imagePlaceHolder: require('../assets/image_place_holder_loading.gif'),
    imagePlaceHolderNotLoaded: require('../assets/image_place_holder_not_available.png'),
    icons: {
        locked: require('../assets/locked_icon.png'),
        yellowButton: require('../assets/yello_go_button.png'),
        activities: require('../assets/icons/ui/activities_icon.png'),
        pace: require('../assets/PACE_alignment.png'),
        statusOk: require('../assets/delivered.png'),
        error: require('../assets/error.png'),
        warn: require('../assets/warn_info.png'),
        closeView: require('../assets/x_close.png'),
        closeViewWhite: require('../assets/x_close_white.png'),
        confusedRobot: require('../assets/confused_robot.png'),
        smallLoader: require('../assets/small_loader.gif'),
        TBC: require('../assets/tbc_logo.png'),
        COC: require('../assets/coc_logo.png'),
        userAvatar: require('../assets/user_vatar.png'),
        digitalBadges: require('../assets/digital_dadges.png'),
        achievments: require('../assets/icons/ui/achievments.png'),
        home: require('../assets/icons/ui/home_icon.png'),
        templates: require('../assets/icons/ui/templates.png'),
        instructions: require('../assets/icons/ui/instructions.png'),
        lessons: require('../assets/icons/ui/lessons_icon.png'),
        notifications: require('../assets/icons/ui/notifications_icon.png'),
        avatarPlaceHolder: require('../assets/icons/ui/user_avatar_icon.png'),
        courses: require('../assets/icons/ui/courses.png'),
        resources: require('../assets/icons/ui/resources.png'),
        feedback: require('../assets/icons/ui/feedback.png'),
        techtalk: require('../assets/icons/ui/techtalk.png'),
        settings: require('../assets/settings.png'),
        share: require('../assets/share.png'),
        history: require('../assets/history.png'),
        email: require('../assets/emailsuppport.png'),
        email2: require('../assets/mail_icon.png'),
        whatsapp: require('../assets/whatsapp_icon.png'),
        guide: require('../assets/help.png'),
        logout: require('../assets/logout.png'),
        emptyBox: require('../assets/icons8-empty-box-96.png'),
        feedback2: require('../assets/feedback_icon.png'),
        minimize: require('../assets/minimize_icon.png'),
        maximize: require('../assets/maxiimize_icon.png'),
        congrats: require('../assets/Congratulations-ICDL_Cats.gif'),
        searchIconBlack: require('../assets/active_search.png'),
        formSteps: [
            require('../assets/icons/form_steps_1.png'),
            require('../assets/icons/form_steps_2.png'),
            require('../assets/icons/form_steps_3.png'),
        ],
        icdl: {
            1: require('../assets/icdl_cats/ICDL_category_1.png'),
            2: require('../assets/icdl_cats/ICDL_category_2.png'),
            3: require('../assets/icdl_cats/ICDL_category_3.png'),
            4: require('../assets/icdl_cats/ICDL_category_4.png'),
            5: require('../assets/icdl_cats/ICDL_category_5.png'),
            6: require('../assets/icdl_cats/ICDL_category_6.png'),
            7: require('../assets/icdl_cats/ICDL_category_7.png'),
            all: require('../assets/icdl_cats/all_categories.png'),
        },
        educatorDashboard: {
            welcome: require('../assets/icons/ui/educator_dashboard/resized/welcome.png'),
            readToGetStarted: require('../assets/icons/ui/educator_dashboard/resized/get_started.png'),
            assessmentsAndFeedback: require('../assets/icons/ui/educator_dashboard/resized/assessments_and_feedback.png'),
            forTheClassRoom: require('../assets/icons/ui/educator_dashboard/resized/for_the_classroom.png'),
            digitalPINS: require('../assets/icons/ui/educator_dashboard/resized/digital_pins.png'),
            planAndOrganize: require('../assets/icons/ui/educator_dashboard/resized/plan_and_organize.png'),
            curriculumOverviews: require('../assets/icons/ui/educator_dashboard/resized/curr_overviews.png'),
            searchLessons: require('../assets/icons/ui/educator_dashboard/resized/search_lessons.png'),
            newsAndUpdates: require('../assets/icons/ui/educator_dashboard/resized/news_and_updates.png'),
            progressReports: require('../assets/icons/ui/educator_dashboard/resized/progress_report.png'),
        }
    },
    fileTyeps: {
        PDF: require('../assets/filetypes/thumb_pdf.png'),
        DOCX: require('../assets/filetypes/thumb_doc.png'),
        DOC: require('../assets/filetypes/thumb_doc.png'),
        WORD: require('../assets/filetypes/thumb_doc.png'),
        PNG: require('../assets/filetypes/thumb_png.png'),
        TIF: require('../assets/filetypes/thumb_tif.png'),
        JPG: require('../assets/filetypes/thumb_jpg.png'),
        JPEG: require('../assets/filetypes/thumb_jpg.png'),
        GIF: require('../assets/filetypes/thumb_gif.png'),
        ZIP: require('../assets/filetypes/thumb_zip.png'),
        MP3: require('../assets/filetypes/thumb_mp3.png'),
        PUB: require('../assets/filetypes/thumb_pub.png'),
        PSD: require('../assets/filetypes/thumb_psd.png'),
        XLSX: require('../assets/filetypes/thumb_excel.png'),
        XLS: require('../assets/filetypes/thumb_excel.png'),
        CDR: require('../assets/filetypes/thumb_cdr.png'),
        HTML5: require('../assets/filetypes/thumb_html5.png'),
        HTML: require('../assets/filetypes/thumb_html.png'),
        MP4: require('../assets/filetypes/thumb_mp4.png'),
        EXE: require('../assets/filetypes/thumb_exe.png'),
        MSCZ: require('../assets/filetypes/thumb_mscz.png'),
        XCF: require('../assets/filetypes/thumb_xcf.png'),
        WAV: require('../assets/filetypes/thumb_wav.png'),
        SB: require('../assets/filetypes/thumb_sb.png'),
        MM: require('../assets/filetypes/thumb_mm.png'),
        DOT: require('../assets/filetypes/thumb_dot.png'),
        BMP: require('../assets/filetypes/thumb_bmp.png'),
        PPT: require('../assets/filetypes/thumb_ppt.png'),
        PPTX: require('../assets/filetypes/thumb_ppt.png'),
        PPS: require('../assets/filetypes/thumb_ppt.png'),
        MCWORLD_EE_MCWORLD: require('../assets/filetypes/thumb_mcworld_ee.png'),
        MCWORLD_WE_MCWORLD: require('../assets/filetypes/thumb_mcworld_we.png'),
        MCWORLD_JE_ZIP: require('../assets/filetypes/thumb_mcworld_je.png'),
    },
    userAvatars: {
        dawood: require('../assets/dawood_avatar_bgwkcbgfdjd.png')
    },
    certsOptions: require('../assets/backgrounds/c4k_or_icd_certifications_smaller.png'),
    schoolyImag: require('../assets/woman-g7271a9255_1920.jpg'),
    taskPage: {
        instructions: require('../assets/icons/ui/new_ui_instructions_icons.png'),
        templates: require('../assets/icons/ui/new_ui_templates_icons.png'),
        resources: require('../assets/icons/ui/new_ui_resources_icons.png'),
        achievements: require('../assets/icons/ui/new_ui_achievements_icons.png'),
        history: require('../assets/icons/ui/new_ui_history_icons.png'),
        share: require('../assets/icons/ui/new_ui_share_icons.png'),
        pace: require('../assets/icons/ui/new_ui_pace_icons.png'),
        parentFeedback: require('../assets/icons/ui/new_ui_parent_feedback_icons.png'),
        alignments: require('../assets/icons/ui/new_ui_alignments_icons.png'),
    }
}

export default Assets;