import React from 'react';
import Assets from '../../lib/Assets';
import Texts from '../../lib/Texts';

/**
 * The empty box
 * @param {string} textProp The test property to use grab from the Texts module. 
 * @returns 
 */
const EmptyBox = ({textProp}) => {
    console.log('{EmptyBox}')
    return (
        <div className='animate__animated animate__fadeIn jshsh text-centre'>
            <img alt='Empyt box' src={Assets.emptyBox} className='empty-box' />
            <p>{Texts[textProp]??Texts.genEmptItems}</p>
        </div>
    )
}
export default EmptyBox