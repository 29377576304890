import React, { useEffect } from 'react'
import AbsoluteCOLLogo from './tools/AbsoluteCOLLogo'
import HomePageNavMenu from './tools/HomePageNavMenu'
import Assets from '../lib/Assets'
import Utils from '../lib/Utils'
import { useParams } from 'react-router-dom'
import QuickHelpView from './tools/QuickHelpView'

/**
 * InactiveAccount view
 * @returns
 */
const InactiveAccount = () => {
  console.log('{InactiveAccount}')
  const supportEmail = 'support@computers4kids.co.za'
  let token = useParams().token??false
  let info
  try {
    info = token ? JSON.parse(Utils.base64Decode(token)) : false
  } catch (e) {
    console.log('error parsing token: ',e.message)
  }
  useEffect(() => {
    Utils.setPageTitle('Inactive Account')
    Utils.addAnalytics({
      page: 'InactiveAccount',
      url: window.location.href,
      title: 'NS.InactiveAccount',
    });
    window.VANTA.HALO({
      el: '#the-animation',
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 800.0,
      minWidth: 600.0,
    });
  }, [])
  return (
    <>
      <div className='jdhd-kdjks animate__animated animate__fadeIn'>
        <div className='mshsh-lkdks'>
          <div className='ksjsjs'>
            <AbsoluteCOLLogo
              styles={{
                left: '20px',
                top: '20px',
              }}
            />
          </div>
          <div className='nshsghd-ldkdj-nav'>
            <HomePageNavMenu page={'home'} />
          </div>
        </div>
        <div className='cbbdg-curriculums-fhgf animate__animated animate__fadeIn'>
          <div id='the-animation'>
            <div className='w-60 centred box-b hdhs'>
              <img alt='' title='Access locked' className='kdk-epep ptr' src={Assets.icons.locked} />
              <h3 className='slks white-text'>
                This account is inactive.
              </h3>
              <p className='slks white-text'>To resume access, please contact us via <a className='ksjs-epep' href={`mailto:${supportEmail}?subject=Resume Access To Classesonoine`}>{supportEmail}</a></p>
              {
                info  &&
                <div className='jshs animate__animated animate__fadeIn'>
                  <p className='slks white-text'>Institution: {info.institute}</p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <QuickHelpView />
    </>
  );
};

export default InactiveAccount
