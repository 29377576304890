import React, { Suspense, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Utils from '../lib/Utils';
import LoadingComponent from './tools/LoadingComponent';
import Error from './tools/Error';
import ReportError from './tools/ReportError';
import Loader from './tools/Loader';
import Assets from '../lib/Assets';
import QuickInfoCollectForm from './tools/QuickInfoCollectForm';
import RightSidePopin from './tools/RightSidePopin';
import SideFeedback from './tools/SideFeedback';
import QuickHelpView from './tools/QuickHelpView';

/**
 * The Quiz Module/App
 * @returns React Comp
 */
const Quiz = () => {
    console.log('{Quiz}');
    let token = useParams().token??false;
    let QuizType;
    if(token) {
        token = JSON.parse(Utils.base64Decode(token));
        console.log('token: ',token);
        QuizType = React.lazy(() => import(`./tools/_quizzes/${token.quizType}`));
    } else {
        QuizType = React.lazy(() => import(`./tools/_quizzes/AboutELearnerQuizzes`));
    }
    const _token = useRef(token);
    useEffect(() => {
        Utils.addAnalytics({
            page: 'Quiz',
            token: _token.current,
            url: window.location.href,
            title: 'NS.QuizPageVisit'
        });
    },[]);
    return (
        <>
        <div className='el-quiz-cont cool-bkg-1 animate__animted animate__fadeIn'>
            <img className='bkg-img-cool' src={Assets.schoolyImag} alt='School Woman' />
            <Suspense fallback={<LoadingComponent />}>
                <QuizType token={token} />
            </Suspense>
        </div>
        <Loader />
        <Error />
        <ReportError />
        <QuickInfoCollectForm />
        <RightSidePopin />
        <SideFeedback />
        <QuickHelpView />
        </>
    )
}

export default Quiz;