import React, { Suspense, useEffect, useRef, useState } from 'react';
import AccountHeader from './tools/AccountHeader';
import ActionModal2 from './tools/ActionModal2';
import Error from './tools/Error';
import Loader from './tools/Loader';
import RightSidePopin from './tools/RightSidePopin';
import StateUpdators from '../lib/StateUpdators';
import Utils from '../lib/Utils';
import { useParams } from 'react-router-dom';
import LoadingComponent from './tools/LoadingComponent';
import Students from './tools/Students';
import ReportError from './tools/ReportError';
import QuickHelpView from './tools/QuickHelpView';


/**
 * Manage students
 * @returns 
 */
const ManageStudents = () => {
    console.log('{ManageStudents}');
    const [Content, setManageStudentsPageContent] = useState({
        C: () => <Students />
    });
    StateUpdators.setManageStudentsPageContent = setManageStudentsPageContent;
    const groups = useRef(useParams().groups??null);
    const loaded = useRef(false);
    useEffect(() => {
        Utils.addAnalytics({
            page: 'ManageStudents',
            url: window.location.href,
            title: `NS.ManageStudents`
        });
        Utils.setPageTitle('Manage Students');
        if(groups.current && !loaded.current) {
            Utils.addAnalytics({
                page: 'ManageStudents.Groups',
                url: window.location.href,
                title: `NS.ManageStudents.Groups`
            });
            Utils.setPageTitle('Manage Students Groups');
            loaded.current = true;
            const View = React.lazy(() => import('./tools/ManageStudentGroups'));
            setManageStudentsPageContent({
                C: () => <View />
            })
        }
    },[]);
    return (
        <>
        <AccountHeader />
        <Suspense fallback={<LoadingComponent />}>
            <Content.C />
        </Suspense>
        <ActionModal2 />
        <Error />
        <ReportError />
        <Loader />
        <RightSidePopin />
        <QuickHelpView />
        </>
    )
}

export default ManageStudents;