import React, { useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Utils from '../../lib/Utils'
import { Button, FormControl, IconButton, InputAdornment, TextField } from '@mui/material'
import { Email, Visibility, VisibilityOff } from '@mui/icons-material'
import Separator from './Separator'
import BaseName from '../../lib/BaseName'
import StateUpdators from '../../lib/StateUpdators'
import Forms from './Forms'
import LoadingComponent from './LoadingComponent'

/**
 * The login view Login part
 * @returns 
 */
const LoginFormViewLoginPart = ({reLogin}) => {
    console.log('{LoginFormViewLoginPart}');
    const [checkRememberMe, setCheckRememberMe] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [input, setInput] = useState({
        username: '',
        password: ''
    })
    const [error, setError] = useState({
        username: false,
        password: false,
        msg: ''
    })
    const [validUsername, setValidUsername] = useState(false);
    const [loggingIn, setLogginIn] = useState(false);
    useEffect(() => {
        let _checkRememberMe = Utils.getLoginPrefs({key: 'checkRememberMe'});
        if(typeof _checkRememberMe.checkRememberMe !== 'undefined') {
            setCheckRememberMe(_checkRememberMe.checkRememberMe);
            const _username = Utils.getLoginPrefs({key: 'checkRememberMe'})['username']??false;
            if(_checkRememberMe.checkRememberMe && _username) {
                setInput((i) => {
                    return {
                        ...i,
                        username: _username
                    }
                })
            }
        }
    },[]);
    return (
        <>
        <div className='ncbs-ldks'>
            <div className='mjd-wpx box-b'>
                <h1 className='mjdjhd-qa'>{`Welcome Back :)`}</h1>
                <p className='mdjes-pa'>{reLogin?`Please login again to your account.`:`Please login to your account.`}</p>
            </div>
            <div className='mjdjhd-soow box-b'>
                <div className='ndsh w-80 centred'>
                    <FormControl fullWidth>
                        <TextField
                            onFocus={() => {
                                if(error.username) {
                                    setError({
                                        ...error,
                                        username: false
                                    })
                                }
                            }}
                            error={error.username}
                            required
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    username: e.target.value
                                })
                                if(e.target.value) {
                                    setValidUsername(true);
                                } else {
                                    setValidUsername(false);
                                }
                            }}
                            onBlur={() => {
                                if(input.username) {
                                    setError({...error, username: false})
                                }
                            }}
                            value={input.username}
                            type='text'
                            label='Username or Email Address:'
                            InputProps={{
                                startAdornment: <InputAdornment position='start'><Email /></InputAdornment>,
                                endAdornment: <InputAdornment position='start'><CheckCircleIcon sx={{color: validUsername?'green':''}} /></InputAdornment>
                            }}
                        >
                        </TextField>
                        {
                            error.username ? <span className='error'>{error.msg}</span>:''
                        }
                        <Separator style={{height: '20px'}} />
                        <TextField
                            error={error.password}
                            required
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {
                                setInput({
                                    ...input,
                                    password: e.target.value
                                })
                            }}
                            value={input.password}
                            aria-label='Toggle password visibility'
                            label='Password:'
                            InputProps={{
                                endAdornment: 
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => {
                                        setShowPassword((show) => !show);
                                    }}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                                </InputAdornment>
                            }}
                            onFocus={() => {
                                if(error.password) {
                                    setError({
                                        ...error,
                                        password: false
                                    })
                                }
                            }}
                        >
                        </TextField>
                        {
                            error.password ? <span className='error'>{error.msg}</span>:''
                        }
                    </FormControl>
                    {
                        loggingIn ? 
                        <>
                        <Separator style={{height: '20px'}} />
                        <div className='content-centre'>
                            <LoadingComponent />
                        </div> 
                        </>: ''
                    }
                </div>
            </div>
            <div className='mdjs-wmsk centred'>
                <div className='content-centre ptr' title='Remember my username next time I login' onClick={() => {
                    if(checkRememberMe) {
                        setCheckRememberMe(false);
                        Utils.setLoginPrefs({key: 'checkRememberMe', value: false});
                    } else {
                        setCheckRememberMe(true);
                        Utils.setLoginPrefs({key: 'checkRememberMe', value: true});
                    }
                }}>
                    <CheckCircleIcon sx={{color: `${checkRememberMe?'green':''}`}} /> <span className='nshs-ws'>Remember me</span>
                </div>
                <div className='content-centre ptr kdjd-wpsks' title='Reset your password'>
                    <Button onClick={() => {
                        Utils.addAnalytics({
                            page: 'Home',
                            url: window.location.href,
                            title: 'NS.Login.ForgotPasswordForm'
                        });
                        StateUpdators.setLoginFormViewLoginPart({
                            C: () => <Forms.LoginFormResetPassword />
                        });
                    }}>
                        Forgot password
                    </Button>
                </div>
            </div>
            <div className='jdjhsh-lws'>
                <div className='content-centre ptr' title='Create your account now'>
                    <Button color='primary' onClick={() => {
                        Utils.addAnalytics({
                            page: 'Home',
                            url: window.location.href,
                            title: 'NS.Login.CreateAccountButtonClick'
                        });
                        Utils.goTo({url: `/${BaseName.name}/signup`});
                    }}>
                        Create Account
                    </Button>
                </div>
                <div className='content-centre ptr' title='Login'>
                    <Button variant='contained' color='primary' className='force-round-btn' onClick={() => {
                        if(!input.username) {
                            setError({
                                ...error,
                                username: true,
                                msg: 'Username is required'
                            })
                            return;
                        } else {
                            setError({
                                ...error,
                                username: false
                            })
                        }
                        if(!input.password) {
                            setError({
                                ...error,
                                password: true,
                                msg: 'Password is required'
                            })
                            return;
                        } else {
                            setError({
                                ...error,
                                password: false
                            })
                        }
                        setLogginIn(true);
                        Utils.addAnalytics({
                            page: 'Home',
                            url: window.location.href,
                            title: 'NS.Login.LoginButtonClick'
                        });
                        const returnUrl = Utils.getReturnURL()
                        Utils.loginUser({...input, 
                            hideLoggingInState: setLogginIn,
                            setValidUsername: setValidUsername,
                            destURL: returnUrl ? returnUrl : `/${BaseName.name}/curriculum`,
                            setPlatform: false,
                            checkRememberMe: checkRememberMe
                        });
                    }}>
                        Login Now
                    </Button>
                </div>
            </div>
        </div>
        </>
    )
}

export default LoginFormViewLoginPart;