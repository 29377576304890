import React, { useState } from 'react'
import StateUpdators from '../../lib/StateUpdators'

/**
 * 
 * @returns 
 */
const CurrentLanguagesList = () => {
    console.log('{CurrentLanguagesList}')
    const [_langs, _setLanguages] = useState('English')
    StateUpdators._setLanguages = _setLanguages
    return (
        <>
        <p className='hfgg-hfhg shady-blk mdj-kf'>{_langs}</p>
        </>
    )
}

export default CurrentLanguagesList