import React,{useState,useEffect} from 'react';
import StateUpdators from '../lib/StateUpdators';
import Utils from '../lib/Utils';
import Error from './tools/Error';
import Loader from './tools/Loader';
import ActionModal2 from './tools/ActionModal2';
import ReportError from './tools/ReportError';
import RightSidePopin from './tools/RightSidePopin';
import QuickHelpView from './tools/QuickHelpView';


/**
 * 
 * @returns 
 */
const Subscribe = () => {
    console.log('{Subscribe}');
    const [curriculum, setCurriculum] = useState({
        Content: () => (<></>)
    });
    StateUpdators.setCurriculumPlans = setCurriculum;
    useEffect(() => {
        Utils.fetchCurriculumSubscriptionPlans();
        Utils.addAnalytics({
            page: 'Subscriptions',
            url: window.location.href,
            title: 'NS.Subscriptions.PageVisit'
        });
    },[]);
    return (
        <>
        {
            <curriculum.Content />
        }
        <Error />
        <RightSidePopin />
        <ReportError />
        <Loader />
        <ActionModal2 />
        <QuickHelpView />
        </>
    )
}

export default Subscribe;